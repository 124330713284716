<template>
<!--    v-touch:swiperight="rightSlide"-->
  <div
    id="rightSwip"

    v-if="modalType == 0"
    :class="backdrop == 1 ? 'backImg JH-body' : 'JH-body'"
  >
    <div class="header_JH" style="padding: 0 8px;box-sizing: border-box" >
      <span>请选择：</span>
<!--      <span-->
<!--              class="pointer backgroundColor"-->
<!--              v-if="true"-->
<!--              @click="changeModalType(1)"-->
<!--      >切换</span-->
<!--      >-->
      <span
        class="pointer backgroundColor"
        v-if="this.userInfo.roleIds == 2"
        @click="goAccess"
        >统计</span
      >
      <span
        class="pointer backgroundColor"
        v-if="this.userInfo.roleIds == 2 && !$store.state.isPhone"
        @click="toManage"
        >权限</span
      >
    </div>
    <div v-if="dataList && dataList.length > 0">
      <div
        :class="
          item.id == maxId
            ? 'content_header_JH content_modelcontent_JH'
            : 'content_modelcontent_JH'
        "
        v-for="(item, index) in dataList"
        @click="jump(item)"
        :key="index"
      >
        <div  style="cursor: pointer;position: relative" :title="item.name">
          <!--          {{item.name}}-->
          <img :src="item.avatar" />
          <div :class="item.state == 0 ? 'isPermissions' : 'permissions'"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="overViewIndex"  :class="backdrop == 1 ? 'backImg' : ''" v-else>
    <div class="enter">
<!--                  <span v-if="true"-->
<!--                        @click="changeModalType(0)" style="margin-right: 10px">切换主题</span>-->
            <span  v-if="this.userInfo.roleIds == 2"
                          @click="goAccess" style="margin-right: 10px">统计</span>
            <span
                    v-if="this.userInfo.roleIds == 2 && !$store.state.isPhone"
                    @click="toManage">管理员入口→</span>
    </div>
    <div class="head">
      <div class="clickLogo">
        <img src="./imgReports/箭头.png" alt="">
        <span class="logoTitle">
                    点击LOGO进入系统查看报告
                </span>
        <img src="./imgReports/箭头.png" alt="">
      </div>
    </div>
    <div v-for="(item,index) in dataClassList" :key="index">
      <div class="enterprise">
        <div class="titleFather">
<!--          <div class="dian"></div>-->
            <span class='title' v-html="item[0].classify.replace(/·/g, `<div style='display:inline-block;width: 0px;height: 0px;border: 1.5px solid #696969;border-radius: 1.5px;margin: 0 5px 3px 5px'></div>`)"></span>
<!--          <div class="dian"></div>-->
        </div>
        <div class="logoArea">
          <div class="logoFather" v-for="(itemInner,keyInner) in item" :key="keyInner">
<!--            <a href="./listReports.html">-->
              <img  @click="jump(itemInner)" :class="itemInner.state == 1 ? 'logo' : 'logo option'" :src="itemInner.avatar" :alt="itemInner.name">
<!--            </a>-->
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import {
  getWeixinModular,
  sendBuriedPointTotal,
  getSignature,
    findWXModularStyleByOrgId
} from "@/api/api";
export default {
  name: "index1",
  data() {
    return {
        backdrop:0,
      modalType: 0,
      userInfo: {},
      departmentName: "", //部门
      appid: "",
      user_code: "",
      moduleName: "",
      reportName: "",
      orgId: "",
      userId: "", //用户id
      name: "", //用户名称
      mobile: "", //手机号
      code: "",
      agentid: "",
      show: false,
      maxId: 0,
      url: "",
      visitTime: "",
      dataList: [],
      dataClassList:[],
      beginDataList:[],
      startX: 0,
      startY: 0,
    };
  },
  directives: {
    //滑动指令
    touch: {
      bind: function (el, binding, vnode) {
        var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0;
        var direction = "";
        //滑动处理
        var startX, startY;

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI;
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY;
          var dx = endX - startX;
          var result = 0;

          //如果滑动距离太短
          if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
            return result;
          }

          var angle = GetSlideAngle(dx, dy);
          if (angle >= -45 && angle < 45) {
            result = "swiperight";
          } else if (angle >= 45 && angle < 135) {
            result = "swipeup";
          } else if (angle >= -135 && angle < -45) {
            result = "swipedown";
          } else if (
            (angle >= 135 && angle <= 180) ||
            (angle >= -180 && angle < -135)
          ) {
            result = "swipeleft";
          }
          return result;
        }

        el.addEventListener(
          "touchstart",
          function (ev) {
            startX = ev.touches[0].pageX;
            startY = ev.touches[0].pageY;

            //判断长按
            timeOutEvent = setTimeout(() => {
              timeOutEvent = 0;
              if (touchType === "press") {
                binding.value();
              }
            }, 500);
          },
          false
        );

        el.addEventListener("touchmove", function (ev) {
          clearTimeout(timeOutEvent);
          timeOutEvent = 0;
        });

        el.addEventListener(
          "touchend",
          function (ev) {
            var endX, endY;
            endX = ev.changedTouches[0].pageX;
            endY = ev.changedTouches[0].pageY;
            direction = GetSlideDirection(startX, startY, endX, endY);

            clearTimeout(timeOutEvent);

            switch (direction) {
              case 0:
                break;
              case "swipeup":
                if (touchType === "swipeup") {
                  binding.value();
                }
                break;
              case "swipedown":
                if (touchType === "swipedown") {
                  binding.value();
                }
                break;
              case "swipeleft":
                if (touchType === "swipeleft") {
                  binding.value();
                }
                break;
              case "swiperight":
                if (touchType === "swiperight") {
                  binding.value();
                }
                break;
              default:
            }
          },
          false
        );
      },
    },
  },
  computed: {
    moduleClass() {
      return { active: true };
    },
  },
  beforeDestroy() {
    this.buriedPointTotal(
      this.agentid,
      this.dateFormat(new Date().valueOf() - this.visitTime.valueOf()),
      this.appid
    );
  },
  methods: {
      async findModularStyleByOrgId(){
          try {
              let result = await findWXModularStyleByOrgId(this.userInfo.orgId)
              this.modalType = String(JSON.parse(result).action)
              this.backdrop = String(JSON.parse(result).backdrop)
          }catch (e) {
              console.log(e)
          }

          // sessionStorage.setItem('action',JSON.parse(String(JSON.parse(result).action)))
          // sessionStorage.setItem('backdrop',JSON.parse(String(JSON.parse(result).backdrop)))
      },

    changeModalType(val){
      console.log(val)
      this.modalType = val
      this.getShowDataList(this.beginDataList)

    },
    rightSlide() {
      window.wx.closeWindow();
    },
    goAccess() {
      this.$router.push({
        path: "/accessStatistics",
        query: {
          needNotLogin: true,
          agentid: this.agentid,
          appid: this.appid,
        },
      });
    },
    toManage() {
      this.$router.push({
        path: "/wxWorkUserManagement",
        query: {
          orgId: this.userInfo.orgId,
          agentid: this.agentid,
          appid: this.appid,
        },
      });
    },
    //时间戳转天数
    dateFormat(times) {
      let s =
        Math.floor((times / 1000) % 60) < 10
          ? "0" + Math.floor((times / 1000) % 60)
          : Math.floor((times / 1000) % 60);
      let m =
        Math.floor((times / 1000 / 60) % 60) < 10
          ? "0" + Math.floor((times / 1000 / 60) % 60)
          : Math.floor((times / 1000 / 60) % 60);
      let h =
        Math.floor(times / 1000 / 60 / 60) < 10
          ? "0" + Math.floor((times / 1000 / 60 / 60) % 24)
          : Math.floor((times / 1000 / 60 / 60) % 24);
      return h + "小时" + m + "分" + s + "秒";
    },
    //埋点
    async buriedPointTotal(agentid, visitDuration, appid) {
      let data = {
        corpId: appid, //企业id
        agentId: agentid, //应用id
        userId: this.userInfo.userId, //用户id
        userName: this.userInfo.userInfo.name, //用户名称
        moduleName: decodeURIComponent(this.moduleName), //模块名称
        moduleId: this.$route.query.moduleId, //模块id
        mobile: this.userInfo.userInfo.mobile, //手机号
        reportName: decodeURIComponent(this.reportName), //报告名称
        departmentName: this.userInfo.department,
        reportId: this.$route.query.reportId, //报告id
        visitType: "", //访问页面类型
        visitUrl: this.url, //访问页面
        visitDuration: visitDuration, //访问时长
        operationName: "", //操作名称
        visitTime: this.visitTime, //访问时间
      };
      try {
        await sendBuriedPointTotal(data);
      } catch (e) {
        console.log(e);
      }
    },
    //  跳转到列表页
    jump(val) {
      if (val.state == 0) {
        this.$router.push({ path: "/noPermissions" });
      } else {
        this.$router.push({
          path: "/reportLists",
          query: {
            moduleId: val.id,
            name: val.name,
            rcode: this.code,
            moduleName: val.name,
            agentid: this.agentid,
            appid: this.appid,
            needNotLogin: true,
          },
        });
      }
    },
    async getSignature(agentid, appid) {
      let url = window.location.href.split("#")[0];
      try {
        let data = {
          url: url,
          agentId: agentid,
        };
        let res = await getSignature(data);
        let result = JSON.parse(res);
        wx.agentConfig({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          corpid: appid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
          nonceStr: String(result.nonce_str), // 必填，生成签名的随机串
          signature: String(result.signature), // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ["onHistoryBack", "closeWindow", "onMenuShareAppMessage"], //必填，传入需要使用的接口名称
          success: function (res) {
            window.wx.onHistoryBack(function () {
              if (window.location.pathname == "/overviewJH") {
                window.wx.closeWindow();
              }
              return true;
            });
            wx.onMenuShareAppMessage({
              title: "舆情报告", // 分享标题
              desc: "随时查阅舆情报告，及时掌握关键信息。 ", // 分享描述
              link: window.location.href, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
              imgUrl:
                "http://inews5.xlmediawatch.com/reportFile/asslogjs/1047/lnewsImg.png", // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
          },
          fail: function (res) {
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getDataList(agentid, appid) {
      // 本地调试
      // let form = {
      //   userId: 15635605813,
      //   orgId: 9542,
      // };
        // 正式
        await this.getSignature(agentid, appid);

          let form = {
            userId: this.userInfo.userId,
            orgId: this.userInfo.orgId,
          };
      try {
        let beginDataList = await getWeixinModular(form);
        // let beginDataList = await this.getFalseModular();
        // let dataList = [...JSON.parse(result).mode];
        this.beginDataList = [...JSON.parse(beginDataList).mode]
          console.log('this.beginDataList',this.beginDataList)
        this.getShowDataList(this.beginDataList)



        await this.buriedPointTotal(this.agentid, -1, this.appid);
      } catch (e) {
        console.log(e);
      }
    },
    getShowDataList(dataList){
        console.log('dataList',dataList)
      // 普通模板
      if(this.modalType == 0){
        this.dataList = dataList.sort((a, b) => {
          return b.sort - a.sort;
        });
        this.maxId = this.dataList[0].id;
      }
      // 分类模板
      else{
        // 用于存放最终数据
        let classArr = []
        let num = 0
          // 用于存放类名
          let classPushArr = []
          // debugger
        for(let outerIndex in dataList){
          if(outerIndex > 0){
              // 遍历每一个元素,判断该类别是否含存在于存放类名的数组中
              if(classPushArr.includes(dataList[outerIndex].classify)){
                  classArr[classPushArr.indexOf(dataList[outerIndex].classify)].push(dataList[outerIndex]);
              }else{
                  // 如果不存在创建创建一个空数组,把该项存放于num+1,即新建的空数组中中
                  num++
                  classArr.push([])
                  classArr[num].push(dataList[outerIndex])
              }
              // 把类名存放在存放类名的数组中
              classPushArr.push(dataList[outerIndex].classify)
              // 类名去重
              classPushArr = Array.from(new Set(classPushArr))

          }else{
            classArr.push([])
            classArr[num].push(dataList[outerIndex])
            classPushArr.push(dataList[outerIndex].classify)
          }
        }
        // 排序
        for(let index in classArr){
          classArr[index] = classArr[index].sort((a, b) => {
            return b.sort - a.sort;
          })
        }
        classArr = classArr.sort((a, b) => {
          return b[0].classSort - a[0].classSort;
        });
        let newClassArr = []
          classArr.map(m => {
              console.log(m)
              m = m.sort((a, b) => {
                  return b.modifiedTime - a.modifiedTime;
              });
              // state 0 分类显示隐藏  0显示1隐藏
              if(m[0].classState == 0){
                  newClassArr.push(m)
              }
          })
        this.dataClassList = newClassArr
        // console.log(classArr)
      }
      this.visitTime = new Date();
      this.show = true;
    },

    async getLoginMsg() {
      //    正式上线
      let agentid = JSON.parse(sessionStorage.getItem("agentid"));
      this.agentid = agentid;
      let appid = JSON.parse(sessionStorage.getItem("appid"));
      this.appid = appid;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.userInfo = userInfo;
        await this.findModularStyleByOrgId()
        await this.getDataList(agentid, appid);


      //  本地调试
      // this.agentid = 1000058;
      // this.appid = "wxc8c8428d0d0fb353";
      // this.userInfo = {};
      // console.log(this.userInfo)
      // this.userInfo.roleIds = 2
      // this.userInfo.orgId = 9542
      //   await this.findModularStyleByOrgId()
      //
      //   await this.getDataList();
    },
  },
  async created() {
    this.url = window.location.href;
    await this.getLoginMsg();
  },
};
</script>

<style scoped lang="less">
.JH-body {
  background-color: #f3f4f9;
  padding-bottom: 0.8vh;
  height: 98%;
  overflow: hidden;
  overflow-y: auto;
  .header_JH {
    display: flex;
    align-items: center;
    height: 4vh;
    padding-bottom: 2vh;
    > :nth-child(1) {
      font-weight: bold;
      color: #43556c;
      font-size: 20px;
    }

    > :nth-child(-n + 3):nth-child(n + 2) {
      padding: 0.5rem 2rem;
      border-radius: 35px;
      border: 1px solid;
      color: white;
    }

    > :nth-child(2) {
      margin-left: auto;
      margin-right: 1vw;
    }
  }
  > div {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > .content_modelcontent_JH {
      cursor: pointer;
      box-sizing: border-box;
      width: 50%;
      height: 12.5vh;
      text-align: center;
      padding: 3px;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        /*border: 1px solid #E1E2E5;*/
        border: none;
        box-shadow: 1px 1px 1px #e1e2e5;
        background-color: #ffffff;
        > img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .isPermissions {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        background-color: black;
        opacity: 0.08;
      }
      .permissions{
        z-index: -1;
      }
    }
    > .content_header_JH {
      cursor: pointer;
      width: 100%;
      height: 25vh;
      text-align: center;
      margin-bottom: 1vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.JH-body::-webkit-scrollbar {
  width: 0;
}
</style>
<style scoped>
  #overViewIndex {
    box-sizing: border-box;
    overflow: hidden;
    min-height: 100vh;
    width: 100vw;
  }
  .backImg{
      background-image: url(./imgReports/底色.png);
      background-size: 100% 100%;
  }
  .enter {
    margin-top: 21px;
    text-align: right;
    margin-left: 22px;
    width: calc(100vw - 44px);
    color: #696969;

    font-size: 13px;
    font-family: '黑体';
  }
  .enter>span{
    cursor: pointer;
    text-decoration: underline;
  }

  .head {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 27px;
  }

  .clickLogo {
    cursor: default;
    margin-top: 12px;
    width: calc(100vw - 44px);
    height: 28px;
    border-radius: 14px;
    background-color: #398CEA;
    line-height: 28px;
    color: white;
    text-align: center;
    font-size: 15px;
    font-family: '黑体';

  }

  .clickLogo>img {
    width: 9px;
    margin-bottom: 2px;
  }

  .enterprise {
    border-radius: 5px;
    color: #696969;
    width: calc(100vw - 44px);
    background-color: white;
    margin-top: 22px;
    margin: 14px 22px;
    padding: 0px 20px 8px 20px;
    box-sizing: border-box;
  }

  .titleFather {
    display: flex;
    align-items: center;
  }

  .dian {
    width: 0px;
    height: 0px;
    border: 1.5px solid #696969;
    border-radius: 1.5px;
  }

  .title {
    font-size: 13px;
    display: inline-block;
    line-height: 35px;
    vertical-align: top;
    font-family: '黑体';
    margin: 0 4px;
  }

  .line {
    margin-left: 22px;
    width: calc(100vw - 44px);
    height: 2px;
    background-color: white;
  }

  .logoArea {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .logoFather {
    margin-top: -12px;
    width: 33.3%;
    overflow: hidden;
    text-align: center;
    padding: 0 0 5px 0;
    box-sizing: border-box;
  }

  .logoFather>a {
    display: block;
  }

  .logo {
    height: 64px;
    cursor: pointer;
  }
    .option{
        opacity: 0.3;
    }
</style>
